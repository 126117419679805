.gridCard4 {
  display: grid;
  grid-template-columns: 20rem auto auto auto auto;
  margin: 2rem 0px 20px 0px;
  align-items: flex-start;
  justify-content: space-between;
}
.gridCard2 {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0px 20px 20px 20px;
}

@media (min-width: 481px) and (max-width: 880px) {
  .gridCard4 {
    grid-template-columns: auto;
    column-gap: 20px;
    row-gap: 20px;
    padding: 2rem 0px 20px 0px;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .gridCard4 {
    grid-template-columns: auto;
    padding: 0rem 0px 20px 0px;
  }
}
