.header__width {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.header__sticky {
  z-index: 1000;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgb(0 21 41 / 8%);
  height: 80px;
  left: calc(50% - 1440px / 2);
  top: calc(50% - 104px / 2 - 4423.5px);
  padding: 1rem;
}
.header__sticky img {
  height: 50px;
  width: auto;
}
.header__button {
  padding: 10px 20px;
  border: 1px solid #f00037;
  border-radius: 4px;
  -webkit-transition: box-shadow 0.3s, background-color 0.3s;
  transition: box-shadow 0.3s, background-color 0.3s;
  color: #f00037;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .header__button {
    padding: 10px;
    border: none;
    border-radius: 0px;
    font-size: 22px;
  }
  .header__sticky img {
    height: 40px !important;
    width: auto;
  }
  .header__sticky svg {
    height: 30px !important;
    width: auto;
  }
  .header__sticky a {
    font-size: 22px;
  }
}
