.custom-prev-button,
.custom-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  width: 2.5rem;
  height: 100%;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.custom-next-button {
  right: -4rem !important;
}
.custom-prev-button {
  left: -4rem !important;
}

@media (max-width: 768px) {
  .custom-next-button,
  .custom-prev-button {
    display: none !important;
  }
}

.swiper-pagination-bullet {
  @apply !h-2 !w-2 !rounded-md !bg-[#ec7b7b];
}
.swiper-pagination-bullet-active {
  @apply !h-2 !w-2 !rounded-md !bg-[#CC1313];
}
.numbered-swiper-pagination .swiper-pagination-bullet {
  @apply !h-8 !w-8 !rounded-full !bg-white p-1 text-center !text-gray-navigation;
}
.numbered-swiper-pagination .swiper-pagination-bullet-active {
  @apply !h-8 !w-8  !rounded-full !bg-gray-pagination text-center text-black;
}

.shadow--blue {
  @apply shadow-xl shadow-[#E9ECFE];
}
.shadow--red {
  @apply shadow-lg shadow-red/20;
}

.shadow--gray {
  @apply shadow-lg shadow-gray-light/20;
}

.btn {
  @apply cursor-pointer whitespace-nowrap rounded-lg p-3 px-8 text-center uppercase disabled:pointer-events-none disabled:bg-gray-light disabled:shadow-none;
}

.btn-red {
  @apply btn shadow--red bg-red text-white;
}

.btn-white {
  @apply btn shadow--red bg-white text-black;
}

.btn-red-outline {
  @apply btn shadow--gray border-2 border-red bg-white text-red;
}

.center {
  @apply flex items-center justify-center;
}
.course-plan-card {
  position: relative;
}
.course-plan-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 5px;
  background: linear-gradient(130deg, #f00037 0%, rgba(204, 19, 19, 0) 70%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.hide-navbar {
  @apply -translate-y-full delay-300 ease-linear;
}

.swiper {
  height: 100%;
}

.swiper-slide {
  height: auto !important;
  text-align: center;
}
.swiper-content {
  height: 100% !important;
}

.gradient-background {
  background: linear-gradient(120deg, #2d2d2d 30%, 40%, #4a4a4a 50%, 75%, #f00037 75% 100%);
}

@media (max-width: 768px) {
  .gradient-background {
    background: linear-gradient(170deg, #2d2d2d 40%, 50%, #4a4a4a 60%, 75%, #f00037 75% 100%);
  }
}

.master-course-bg {
  background: -webkit-radial-gradient(top right, #8f37ff, #daa8e2);
  background: radial-gradient(at top right, #8f37ff, #daa8e2);
}

.pg-course-bg {
  background: -webkit-radial-gradient(top right, #1bc5b1, #00d6ad);
  background: radial-gradient(at top right, #1bc5b1, #00d6ad);
}

.absolute-width {
  width: calc(50% - 35px);
}

.bytes-card-bg {
  background: url('/assets/radial.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cm-gutters {
  background-color: #1d2939 !important;
  padding: 0 0.75rem !important;
}

.cm-gutterElement {
  text-align: center !important;
  font-size: 14px;
  color: #a3a3a3;
  padding: 0 !important;
}

.cm-scroller {
  padding: 0 !important;
}

.cm-content {
  padding-top: 0.5rem !important;
}

.margin-view-overlays {
  background-color: #1d2939 !important;
  width: 50px !important;
}

.line-numbers {
  text-align: center !important;
}

.tapHighlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Add these styles to your global CSS or a style module */
@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-in-up {
  animation: slideInUp 0.2s ease-in-out;
}

.program-cohort-table tr th {
  padding: 12px 24px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505050;
  min-width: 200px;
}

.program-cohort-table tr td {
  padding: 16px 24px;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #646464;
  min-width: 200px;
}

.separator-dark {
  background: linear-gradient(
    rgba(24, 24, 24, 0.25) -2.34%,
    rgba(255, 255, 255, 0.2) 48.3%,
    rgba(0, 0, 0, 0.1) 100%,
    rgba(24, 24, 24, 0.25) 100%
  );
  height: 64px;
  width: 2px;
}

.separator {
  background: linear-gradient(
    rgba(255, 255, 255, 0) -2.34%,
    rgba(0, 0, 0, 0.65) 47.23%,
    rgba(0, 0, 0, 0.1) 100%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 64px;
  width: 2px;
}

.chipCard-dark {
  border-radius: 8px;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  border-width: 1px;
  border-style: solid;
  border-color: linear-gradient(rgba(255, 255, 255, 0.05));
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 8px 16px 0px rgba(4, 4, 4, 0.05);
  backdrop-filter: blur(25px);
}

.chipCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.gradient-border-dark-145 {
  background: linear-gradient(#1a1a1a, #1a1a1a) padding-box,
    linear-gradient(145deg, #ffffff1a, transparent) border-box;
  border: 1px solid transparent;
}

.gradient-border-dark {
  background: linear-gradient(#1a1a1a, #1a1a1a) padding-box,
    linear-gradient(145deg, rgba(255, 255, 255, 0.4), #292929) border-box;
  border: 1px solid transparent;
}

/* swiper-pagination-bullet swiper-pagination-bullet-active */

.dotted-red-pagination .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background-color: #b9b9b9 !important;
  border-radius: 4px !important;
}

.dotted-red-pagination .swiper-pagination-bullet-active {
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px !important;
  background-color: #d80e32 !important;
}

.eventCard--dark {
  border-radius: 32px;
  border: 1px solid transparent;
  background: radial-gradient(74.22% 64.62% at 26.36% 14.29%, #1a1a1a 0%, #151515 50%, #000 100%)
      padding-box,
    linear-gradient(145deg, #ffffff1a, transparent) border-box;
}

.gradient-border-gray-145 {
  border: 1px solid transparent;
  background: linear-gradient(rgba(255, 255, 255, 0.3), transparent) padding-box,
    linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) border-box;
}

.gray-out-pagination .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background-color: #434343 !important;
  border-radius: 4px !important;
}

.gray-out-pagination .swiper-pagination-bullet-active {
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px !important;
  background-color: white !important;
}

.flatted-red-pagination .swiper-pagination-bullet {
  width: 72px !important;
  height: 6px !important;
  background-color: #b9b9b9 !important;
  border-radius: 4px !important;
}

.flatted-red-pagination .swiper-pagination-bullet-active {
  width: 72px !important;
  height: 6px !important;
  border-radius: 4px !important;
  background-color: #d80e32 !important;
}

.black-overlay--180deg {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.newSuccessCard--redBackground {
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(180deg, #ff184d 50%, #901c36 106.04%);
}

.primary-timeline {
  background: linear-gradient(to top, #1a1a1a, #1a1a1a 15%, #f11448 70%, #f00037 100%);
}

.dark-overlay {
  background: radial-gradient(ellipse at bottom right, transparent, 15%, #1a1a1a 50%, #151515 100%);
}

.gray-dotted-pagination .swiper-pagination-bullet {
  @apply !h-4 !w-4 !rounded-full !bg-white p-1 text-center !text-gray-navigation;
}
.gray-dotted-pagination .swiper-pagination-bullet-active {
  @apply !h-4 !w-4  !rounded-full !bg-gray-pagination text-center text-black;
}
