@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fontsource/dm-sans';
@import url('./satoshi.css');

@font-face {
  font-family: 'gellix-medium';
  src: url('https://almablog-media.s3.ap-south-1.amazonaws.com/gellix-medium-webfont.woff2')
    format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'gellix-semibold';
  src: url('https://almablog-media.s3.ap-south-1.amazonaws.com/gellix-semibold-webfont.woff2')
    format('woff2');
  font-display: block;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #4a4a4a;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  @apply text-gray-500;
  font-family: 'gellix-medium', 'sans-serif';
  font-display: block;
  font-size: 18px;
  line-height: 1.667em;
  font-weight: 400;
  /*scroll-behavior: smooth;*/
  height: 100%;
  /* width: 100%; */
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-satoshi-bold font-satoshi-medium font-semibold;
}

h1 {
  @apply text-5xl;
}

h2 {
  @apply text-2xl md:text-3xl;
}

.header-h2 {
  @apply text-[28px];
}

button {
  @apply font-satoshi-medium  font-satoshi-medium font-semibold uppercase;
}
.btn {
  @apply font-satoshi-medium  font-satoshi-medium font-semibold uppercase;
}
/* .swiper-pagination {
  margin-top: 1000px !important;
} */
.swiper-pagination-bullet {
  @apply !h-2 !w-6 !rounded-md !bg-[#ec7b7b];
}
.swiper-pagination-bullet-active {
  @apply !h-2 !w-10 !rounded-md !bg-[#F00037];
}

.swiper-button-next {
  right: 0rem !important;
}
.swiper-button-prev {
  left: 0rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: 3.5rem;
  width: 2rem;
  padding: 1rem !important;
  /* background-color: white !important; */
  background-image: url(../public/next-alma.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-size: 1.3rem;
  position: absolute;
  top: 44% !important;
  /* position: absolute; */
  /* bottom: 0; */
  /* top: 100; */
  /* right: 20px; */
}

.swiper-button-prev {
  transform: rotate(180deg) !important;
}
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next::after {
  display: none;
}

.text-body {
  @apply font-satoshi-medium  font-normal text-gray-300;
}

@media (max-width: 480px) {
  h1 {
    @apply text-[38px] leading-9;
  }
  .header-h2 {
    @apply text-2xl leading-6;
  }

  button {
    @apply text-[15px];
  }
  .btn {
    @apply text-[15px];
  }

  .text-body {
    @apply text-[16px];
  }

  .text-para {
    @apply text-[16px];
  }
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .no-select:focus {
    outline: none !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

summary {
  display: block;
  position: relative;
}

summary::after {
  float: right;
  cursor: pointer !important;
  transition: 0.2s;
  font-size: 1.8rem;
  opacity: 0.5;
  content: '\002B'; /* chevron */
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
}

details[open] summary::after {
  content: '\2212';
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
}

a {
  color: inherit;
  text-decoration: none;
}
p {
  font-weight: 500;
  /* font-size: 24px; */
  line-height: 34px;
}
* {
  box-sizing: border-box;
}
h1 {
  font-weight: 900;
  /* font-size: 48px; */
  line-height: 1.4em;
  letter-spacing: -1px;
}
h2 {
  font-weight: 900;
  /* font-size: 36px; */
  line-height: 1.3em;
  letter-spacing: -1px;
}
.container-default-1209px {
  max-width: 1209px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  margin: auto;
  /* padding-right: 24px;
  padding-left: 24px; */
}
@media screen and (max-width: 767px) {
  .heading-1 {
    font-size: 42px !important;
    line-height: 1.3em;
    letter-spacing: -1px;
  }
  .text-lg-para {
    font-size: 24px;
    line-height: 1.4em;
  }
  .heading-2 {
    font-size: 34px !important;
    line-height: 1.3em;
  }
  .card-heading {
    font-size: 28px !important;
    line-height: 1.4em;
  }
  .card-para {
    font-size: 22px !important;
    line-height: 1.4em;
  }
  .heading-3 {
    font-size: 22px !important;
    line-height: 1.4em;
  }
  .text-sm {
    font-size: 20px !important;
    line-height: 1.4em;
  }
  .text-xs {
    font-size: 14px;
    line-height: 18.23px;
  }
  .btn-explore {
    font-size: 22px;
    padding: 8px 30px !important;
  }
  .btn-sm-explore {
    font-size: 22px;
  }
  .text-lg a {
    font-size: 22px !important;
  }
  .btn-course {
    padding: 15px 40px;
    font-size: 22px;
  }
  .m-bottom {
    margin-bottom: 15% !important;
  }
}
@media screen and (min-width: 1440px) {
  .container-default-1209px {
    max-width: 1320px;
    padding-right: 24px;
    padding-left: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1439px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    letter-spacing: -1px;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
  }
}

@media screen and (max-width: 991px) {
  body {
    width: 120vw !important;
  }
  .container-default-1209px {
    width: 100%;
  }
}

li {
  text-decoration: none;
}

/* header */
.overflow-hidden {
  overflow: hidden;
}
.main-nav {
  min-height: 80px;
  background: transparent;
}

.main-nav .nav {
  float: right;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.main-nav .nav li {
  @apply font-satoshi-bold;
  padding-left: 15px;
  padding-right: 15px;
}

.main-nav .nav li:last-child {
  padding-right: 0px;
}

.main-nav .nav li a,
.main-nav .nav li span {
  @apply font-satoshi-bold;
  display: block;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.main-nav .nav li:hover a,
.main-nav .nav li a.active {
  color: #f00037 !important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #f00037 !important;
  opacity: 1;
}

.main-nav .nav li.submenu {
  @apply font-satoshi-medium;
  position: relative;
}

.main-nav .nav li.submenu ul {
  @apply font-satoshi-bold;
  position: absolute;
  width: max-content;
  padding: 10px;
  /* box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06); */
  overflow: hidden;
  top: 50px;
  opacity: 0;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.main-nav .nav li.submenu ul li {
  @apply font-satoshi-bold;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.main-nav .nav li.submenu ul li a {
  @apply font-satoshi-bold;
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  padding-right: 20px;
}

.main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #f00037 !important;
  /* padding-right: 25px; */
}

.main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-sticky .nav li a.active {
  color: #f00037;
}

@media (max-width: 1200px) {
  .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .main-nav:before {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-area .main-nav .logo {
    color: #f00037;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #f00037 !important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    /* box-shadow: none; */
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #f00037 !important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    /* box-shadow: none; */
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 16px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    /* color: #3b566e; */
    right: 25px;
    font-size: 18px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .main-nav .nav {
    display: flex !important;
  }
}

.image-item {
  padding: 20px 0 20px 20px;
  margin-right: 80px;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .image-item {
    padding: 0px 0 20px 20px;
    margin-right: 80px;
    object-fit: cover;
  }
}

/* animation */
/* .values_variables__2NhcW {
  --space: calc(80px);
  --outer-radius: calc(calc(100vh - var(--space)) / calc(2 * 0.825));
  --inner-radius: calc(0.6 * var(--outer-radius));
  --radius: calc(calc(var(--outer-radius) + var(--inner-radius)) / 2);
  --bg-color: #4b59a7;
  max-width: calc(var(--outer-radius) * 2);
}
.values_outer_container__9UplM {
  width: calc(var(--outer-radius) * 2);
  height: calc(var(--outer-radius) * 2);
  -webkit-clip-path: polygon(0 0, 0 82.5%, 100% 82.5%, 100% 0);
  clip-path: polygon(0 0, 0 82.5%, 100% 82.5%, 100% 0);
}
.values_outer_container__9UplM:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  opacity: 0.4;
  background-color: var(--bg-color);
  z-index: -1;
}
.values_inner_container__1IMNE {
  --displacement: calc(var(--inner-radius) - calc(13rem / 2) - 2rem);
  background-color: var(--bg-color);
  width: calc(var(--inner-radius) * 2);
  height: calc(var(--inner-radius) * 2);
}
.values_position__33T7s {
  --rotation: calc(var(--position) * 25deg + calc(var(--sign) * 20deg));
  transform: rotate(var(--rotation)) translateY(calc(-1 * var(--radius)))
    rotate(calc(-1 * var(--rotation))) translateY(var(--displacement))
    scale(calc(1 / 2));
}
.values_position__33T7s:hover:after {
  content: "";
  position: absolute;
  height: 15rem;
  width: 15rem;
  border-radius: 9999px;
  z-index: -1;
  background-color: var(--bg-color);
}
.values_periphery__aO9UX,
.values_position__33T7s:hover:after {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.values_periphery__aO9UX {
  transition-duration: 0.5s;
  transition-timing-function: linear;
  --rotation: calc(calc(var(--position) / -4) * 60deg);
  transform: rotate(var(--rotation)) translateY(calc(var(--radius)))
    rotate(calc(-1 * var(--rotation))) translateY(var(--displacement))
    scale(calc(1 / 2));
}
.values_header__Q3opp {
  transform: translateY(calc(-1 * var(--radius)));
}
.values_float_wrapper__1UxOh {
  -webkit-animation: values_animate-float-x__1Oiov 4s ease-in-out infinite
    alternate;
  animation: values_animate-float-x__1Oiov 4s ease-in-out infinite alternate;
}
.values_float__3RnN3 {
  -webkit-animation: values_animate-float-y__3by9r 3s ease-in-out infinite
    alternate;
  animation: values_animate-float-y__3by9r 3s ease-in-out infinite alternate;
}
@-webkit-keyframes values_animate-float-x__1Oiov {
  0% {
    transform: translateX(calc(var(--magnitude) * -0.6rem));
  }
  to {
    transform: translateX(calc(var(--magnitude) * 0.9rem));
  }
}
@keyframes values_animate-float-x__1Oiov {
  0% {
    transform: translateX(calc(var(--magnitude) * -0.6rem));
  }
  to {
    transform: translateX(calc(var(--magnitude) * 0.9rem));
  }
}
@-webkit-keyframes values_animate-float-y__3by9r {
  0% {
    transform: translateY(calc(var(--magnitude) * -0.5rem));
  }
  to {
    transform: translateY(calc(var(--magnitude) * 0.9rem));
  }
}
@keyframes values_animate-float-y__3by9r {
  0% {
    transform: translateY(calc(var(--magnitude) * -0.5rem));
  }
  to {
    transform: translateY(calc(var(--magnitude) * 0.9rem));
  }
} */

.slider-crousel {
  width: 80px;
  padding: 10px;
  margin-bottom: 10px;
}
.carousel-container {
  padding-bottom: 30px;
}

.modal-open {
  opacity: 0.75;
  background-color: #fff;
  /* z-index: -1; */
}

.example-container {
  overflow: hidden;
}
.column-1,
.column-2,
.column-3 {
  padding-bottom: 99999px;
  margin-bottom: -99999px;
  float: left;
}

.player-wrapper {
  width: auto;
  height: auto;
}

.text-underline:after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  height: 5px;
  top: 23px;
  width: 100%;
  background: linear-gradient(90deg, #2d23a6 0%, rgba(225, 14, 90, 0.99) 100%);
}

.promotion-gradient {
  background: linear-gradient(90.14deg, #0c0454 0%, #53194d 100%);
}

/* markdown style */
.listing p {
  display: inline;
}

.blockquote p {
  margin-bottom: 0;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
}
.hire-dots .react-multi-carousel-dot button {
  border: none;
  background: #d9d9d9;
}
.hire-dots .react-multi-carousel-dot--active button {
  background: #3c65f6;
}

.hire-dots-red .react-multi-carousel-dot button {
  border: none;
  background: #d9d9d9;
}
.hire-dots-red .react-multi-carousel-dot--active button {
  background: #f00037;
}

.imageHolder {
  width: 55%;
}

.cardHolder {
  width: 45%;
}

.stripBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}

@media (max-width: 1279px) {
  .imageHolder {
    width: 100%;
  }

  .cardHolder {
    width: 100%;
    row-gap: 0.75rem;
    padding: 1rem 0 1.5rem 1rem;
  }

  .cardHolder h4 {
    font-size: 26px;
  }

  .cardHolder div p {
    font-size: 16px;
  }

  .cardHolder div button {
    font-size: 20px;
    padding: 0.5rem 1.5rem;
  }

  .stripBanner {
    justify-content: unset;
    column-gap: 0.5rem;
  }
}

@media (max-width: 975px) {
  .cardHolder {
    row-gap: 1rem;
  }

  .cardHolder h4 {
    font-size: 30px;
  }

  .cardHolder div p {
    font-size: 20px !important;
  }

  .cardHolder div button {
    font-size: 24px;
    padding: 0.75rem 2rem;
  }
}

@media (max-width: 500px) {
  .cardHolder {
    row-gap: 0.75rem;
  }

  .cardHolder h4 {
    font-size: 24px;
  }

  .cardHolder div p {
    font-size: 16px !important;
  }

  .cardHolder div button {
    font-size: 16px;
    padding: 0.5rem 1.5rem;
  }
}

.slim-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.slim-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
}

.slim-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.slim-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
}
