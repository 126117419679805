.eventContainer:nth-of-type(odd) {
  background: #eef9fd;
  border: 14px solid #eef9fd;
}

.eventContainer:nth-of-type(even) {
  background: #ebfaf4;
  border: 14px solid #ebfaf4;
}

.eventContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 1rem;
  margin: 2.5rem 0;
  padding: 1rem;
  border-radius: 0.375rem;
}

@media (min-width: 1024px) {
  .eventContainer {
    flex-direction: row;
  }
}
